<template>
    <div class="relative mb-6 flex h-full w-full flex-col lg:mb-16 lg:mt-0">
        <div class="mb-2 h-auto justify-between lg:mb-5 lg:flex lg:flex-row">
            <div
                class="mr-1 text-sm font-bold capitalize leading-tight text-black md:leading-normal lg:text-[18px]"
            >
                {{ $t('home.popular_game') }}
            </div>
            <div class="hidden lg:block">
                <ThaCommonViewMore :link="`/games/slots?partner=`" />
            </div>
        </div>
        <div>
            <div class="grid grid-cols-2 gap-2 lg:grid-cols-6 lg:gap-4">
                <template
                    v-for="hotGame in hotGames.items"
                    :key="hotGame.partner_game_id"
                >
                    <ThaCommonGameCard
                        :data="hotGame"
                        :jackpotvalue="findJacpot(hotGame)"
                        @click="handleGetGameUrl(hotGame)"
                    />
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>
import {
    useGamesStore,
    useAlertStore,
    useJackpotStore,
    useUserStore,
    useModalStore,
} from '~/stores'
import { storeToRefs } from 'pinia'

const alertStore = useAlertStore()

const gameStoreInstance = useGamesStore()

const { getGameUrl } = gameStoreInstance

const useJackpotStoreInstance = useJackpotStore()
const { jackpots } = storeToRefs(useJackpotStoreInstance)
const { isMobile } = useDevice()
const useUserStoreInstance = useUserStore()
const useModalStoreInstance = useModalStore()
const handleGetGameUrl = async (payload) => {
    if (!useUserStoreInstance.isLoggedIn) {
        useModalStoreInstance.showLoginModal = true
        return
    }
    const toSend = {
        partnerProvider: payload.partner_provider,
        partnerGameId: payload.partner_game_id,
    }
    if (isMobile) {
        const newtab = window.open('', '_blank')
        try {
            const { data } = await getGameUrl(toSend)
            if (data?.value?.status === 'OK') {
                const { url } = data.value.data
                if (newtab) newtab.location.href = url
            } else if (data?.value?.data?.url === '') {
                if (newtab) newtab.close()
                // NO URL recieved
                alertStore.showMessageError({
                    value: {
                        statusCode: 'SHOW_MESSAGE',
                        message: t('error.server_is_busy'),
                    },
                })
            } else if (data.value === null) {
                // no auth
                if (newtab) newtab.close()
            }
        } catch (error) {
            if (newtab) newtab.close()
            alertStore.showMessageError(error)
        }
    } else {
        const { data } = await getGameUrl(toSend)
        if (data?.value?.status === 'OK') {
            const { url } = data.value.data
            navigateTo(url, {
                open: {
                    target: '_blank',
                },
            })
        }
    }
}
const findJacpot = (game) => {
    return jackpots.value[game.partner_game_id]
}
const hotGames = ref([])
const {
    execute,
    data: hotGamesApi,
    error,
} = useApi('/game/search/?type=hot&limit=12', {
    pick: ['data', 'status'],
    immediate: false,
    onResponse({ response }) {
        if (response._data?.status === 'OK') {
            // TODO this one did not work on dev
            // hotGames.value = response._data.data
        }
    },
})
await execute()
hotGames.value = hotGamesApi?.value?.data
// refetch when language changes
const { locale } = useI18n()
watch(
    () => locale.value,
    async (newVal, oldVal) => {
        if (newVal !== oldVal) {
            await execute()
            hotGames.value = hotGamesApi?.value?.data
        }
    },
    { immediate: false, deep: false }
)
</script>

<style lang="scss" scoped>
:deep() {
    .loader-image {
        background: none !important;
    }
}
</style>
